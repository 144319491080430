import { call, put, all, fork } from 'redux-saga/effects';
import { takeLatestAction, takeEveryAction } from 'Utils/sagaUtils';
import * as emailService from 'Api/Webinar/Settings/Email/emailService';
import EmailType from 'Components/Card/Events/Details/Emails/Model/emailType';
import capitalize from 'Utils/stringUtils';
import { uploadFileToS3 } from '../../Api/AWS/awsService';
import { createfileUploadPayload } from '../../Api/Helpers/upload';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { retrieveReplyToAction,
  updateReplyToAction,
  retrieveFollowUpAction,
  updateFollowUpAction,
  retrieveReminderEmailAction,
  updateReminderEmailAction,
  retrieveConfirmationEmailAction,
  updateConfirmationEmailAction,
  getCustomEmailDisclaimerAction,
  updateEmailDisclaimerAction,
  getCertificateInfoAction,
  createSignatureUploadPolicyAction,
  uploadFileToS3Action,
  updateCertificateInfoAction,
  deleteCertificateInfoAction,
  getCertificateUrlAction } from './actionTypes';
import { viewCertificate } from './emailHelper';
import { trackSignatureUploaded } from '../../Mixpanel/Features/EventDetails/CustomiseCertificate/certificateTracker';

export function * retrieveReplyTo(action) {
  try {
    const data = yield call(emailService.retrieveReplyTo, action.webinarKey);
    yield put({ type: retrieveReplyToAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: retrieveReplyToAction.failed.toString(),
      error
    });
  }
}

export function * updateReplyTo(action) {
  try {
    const data = yield call(emailService.updateReplyTo, action.webinarKey, action.replyToObj);
    yield put({ type: updateReplyToAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.replyToEmail', isError: false });
  } catch (error) {
    yield put({
      type: updateReplyToAction.failed.toString(),
      error
    });
    const capitalizedEmailType = capitalize(EmailType.REPLY_TO);
    const messageKeyParams = [{ emailType: `${capitalizedEmailType}` }];
    switch (error.body.validationErrorCodes[0].code) {
      case 'invalid':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidEmailFail', isError: true });
        break;
      default:
        yield put({
          type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.failed', messageKeyParams, isError: true
        });
    }
  }
}

export function * retrieveFollowUpEmail(action) {
  try {
    const data = yield call(emailService.retrieveWebinarFollowUpEmail, action.webinarKey, action.attendeeType);
    yield put({ type: retrieveFollowUpAction.complete.toString(), data, attendeeType: action.attendeeType });
  } catch (error) {
    yield put({
      type: retrieveFollowUpAction.failed.toString(),
      attendeeType: action.attendeeType,
      error
    });
  }
}

export function * updateFollowUpEmail(action) {
  try {
    let messageKey;
    yield call(emailService.updateWebinarFollowUpEmail, action.webinarKey, action.followUpEmailObj, action.attendeeType);
    yield put({ type: updateFollowUpAction.complete.toString(), attendeeType: action.attendeeType });
    if (action.includeCertificate) {
      messageKey = action.followUpEmailObj.includeCertificate ? 'cards.webinar.details.email.details.certificate.certificateAdded' : 'cards.webinar.details.email.details.certificate.certificateRemoved';
    } else {
      messageKey = action.followUpEmailObj.toBeSent ? 'cards.webinar.details.email.notification.willBeSent' : 'cards.webinar.details.email.notification.willNotBeSent';
    }
    const capitalizedEmailType = capitalize(action.followUpEmailObj.type);
    const messageKeyParams = [{ emailType: `${capitalizedEmailType} ${EmailType.FOLLOW_UP}` }];
    yield put({
      type: SEND_NOTIFICATION, messageKey, messageKeyParams, isError: false
    });
  } catch (error) {
    yield put({
      type: updateFollowUpAction.failed.toString(),
      attendeeType: action.attendeeType,
      error
    });
    const capitalizedEmailType = capitalize(action.followUpEmailObj.type);
    const messageKeyParams = [{ emailType: `${capitalizedEmailType} ${EmailType.FOLLOW_UP}` }];
    switch (error.body.validationErrorCodes[0].code) {
      case 'api.PanelistResource.subjectXssNotAllowed':
      case 'api.FollowUpEmailResource.subjectXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidSubjectFail', isError: true });
        break;
      case 'api.PanelistResource.messageXssNotAllowed':
      case 'api.FollowUpEmailResource.messageXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidCustomTextFail', isError: true });
        break;
      default:
        yield put({
          type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.failed', messageKeyParams, isError: true
        });
    }
  }
}

export function * retrieveReminderEmail(action) {
  try {
    const data = yield call(emailService.retrieveReminderEmail, action.webinarKey);
    yield put({ type: retrieveReminderEmailAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: retrieveReminderEmailAction.failed.toString(),
      error
    });
  }
}

export function * updateReminderEmail(action) {
  try {
    yield call(emailService.updateReminderEmail, action.webinarKey, action.reminderEmailObj);
    yield put({ type: updateReminderEmailAction.complete.toString(), data: action.reminderEmailObj });
    const messageKey = action.reminderEmailObj.enabled ? 'cards.webinar.details.email.notification.willBeSent' : 'cards.webinar.details.email.notification.willNotBeSent';
    const messageKeyParams = [{ emailType: `${capitalize(EmailType.REMINDER)}` }];
    yield put({
      type: SEND_NOTIFICATION, messageKey, messageKeyParams, isError: false
    });
  } catch (error) {
    yield put({
      type: updateReminderEmailAction.failed.toString(),
      error
    });
    const messageKeyParams = [{ emailType: `${capitalize(EmailType.REMINDER)}` }];
    switch (error.body.validationErrorCodes[0].code) {
      case 'api.ReminderEmailResource.subjectXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidSubjectFail', isError: true });
        break;
      case 'api.ReminderEmailResource.messageXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidCustomTextFail', isError: true });
        break;
      default:
        yield put({
          type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.failed', messageKeyParams, isError: true
        });
    }
  }
}
export function * retrieveConfirmationEmail(action) {
  try {
    const data = yield call(emailService.retrieveConfirmationEmail, action.webinarKey);
    yield put({ type: retrieveConfirmationEmailAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: retrieveConfirmationEmailAction.failed.toString(),
      error
    });
  }
}

export function * updateConfirmationEmail(action) {
  try {
    yield call(emailService.updateConfirmationEmail, action.webinarKey, action.confirmationEmailObj);
    yield put({ type: updateConfirmationEmailAction.complete.toString(), data: action.confirmationEmailObj });
    const messageKey = action.confirmationEmailObj.sendConfirmationEmail ? 'cards.webinar.details.email.notification.willBeSent' : 'cards.webinar.details.email.notification.willNotBeSent';
    const messageKeyParams = [{ emailType: `${capitalize(EmailType.CONFIRMATION)}` }];
    yield put({
      type: SEND_NOTIFICATION, messageKey, messageKeyParams, isError: false
    });
  } catch (error) {
    yield put({
      type: updateConfirmationEmailAction.failed.toString(),
      error
    });
    const messageKeyParams = [{ emailType: `${capitalize(EmailType.CONFIRMATION)}` }];
    switch (error.body.validationErrorCodes[0].code) {
      case 'api.ConfirmationEmailResource.subjectXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidSubjectFail', isError: true });
        break;
      case 'api.ConfirmationEmailResource.messageXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.invalidCustomTextFail', isError: true });
        break;
      default:
        yield put({
          type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.notification.failed', messageKeyParams, isError: true
        });
    }
  }
}

export function* fetchCustomEmailDisclaimer(action) {
  try {
    const data = yield call(emailService.getEmailCustomDisclaimer, action.webinarKey);
    yield put({ type: getCustomEmailDisclaimerAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getCustomEmailDisclaimerAction.failed.toString(), error });
  }
}

export function * updateEmailDisclaimer({ webinarKey, customEmailDisclaimer }) {
  try {
    yield call(emailService.updateCustomEmailDisclaimer, webinarKey, customEmailDisclaimer);
    yield put({ type: updateEmailDisclaimerAction.complete.toString(), data: customEmailDisclaimer });
    yield put({
      type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.details.emailSection.customEmailDisclaimer.successNotification', isError: false
    });
  } catch (error) {
    yield put({ type: updateEmailDisclaimerAction.failed.toString(), error });
    yield put({
      type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.details.emailSection.customEmailDisclaimer.errorNotification', isError: true
    });
  }
}

export function* fetchCertificateInfo({ webinarKey, showCertificateModal }) {
  try {
    const data = yield call(emailService.getCertificateInfo, webinarKey);
    yield put({ type: getCertificateInfoAction.complete.toString(), data });
    if (typeof showCertificateModal === 'function') {
      showCertificateModal();
    }
  } catch (error) {
    yield put({ type: getCertificateInfoAction.failed.toString(), error });
  }
}

export function* updateCertificateInfo({ webinarKey, certificateInfo, fileMetaData, file, signatureType }) {
  try {
    if (certificateInfo.isSignaturePresent) {
      try {
        // Call to get upload policy for s3 upload
        const uploadPolicy = yield call(emailService.createSignatureUploadPolicy, webinarKey, fileMetaData);
        yield put({ type: createSignatureUploadPolicyAction.complete.toString(), uploadPolicy });
        if (uploadPolicy && uploadPolicy.fileUploadTicket) {
          const payload = createfileUploadPayload(uploadPolicy.fileUploadTicket.formData, file);
          yield call(uploadFileToS3, uploadPolicy.fileUploadTicket.uploadUrl, payload);
          trackSignatureUploaded(signatureType, webinarKey);
          yield put({ type: uploadFileToS3Action.complete.toString() });
        }
      } catch (error) {
        throw new Error({ isSignatureError: true });
      }
    }
    yield call(emailService.updateCertificateInfo, webinarKey, certificateInfo);
    yield put({ type: updateCertificateInfoAction.complete.toString(), data: certificateInfo });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.details.certificate.certificateInfo.updatedSuccessMessage', isError: false });
  } catch (error) {
    if (error.isSignatureError) {
      yield put({ type: createSignatureUploadPolicyAction.failed.toString(), error });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.details.certificate.certificateInfo.signatureFailed', isError: true });
    } else {
      yield put({ type: updateCertificateInfoAction.failed.toString(), error });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
    }
  }
}

export function* deleteCertificateInfo({ webinarKey }) {
  try {
    yield call(emailService.deleteCertificateInfo, webinarKey);
    yield put({ type: deleteCertificateInfoAction.complete.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.email.details.certificate.certificateInfo.updatedSuccessMessage', isError: false });
  } catch (error) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
    yield put({ type: deleteCertificateInfoAction.failed.toString(), error });
  }
}

export function* getCertificateUrl({ webinarKey }) {
  try {
    const data = yield call(emailService.getCertificateUrl, webinarKey);
    if (data.certificateUrl) {
      viewCertificate(data.certificateUrl);
      yield put({ type: getCertificateUrlAction.complete.toString(), data });
    }
  } catch (error) {
    yield put({ type: getCertificateUrlAction.failed.toString(), error });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

export default function * rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, retrieveReplyToAction.toString(), retrieveReplyTo)),
    fork(takeLatestAction.bind(this, updateReplyToAction.toString(), updateReplyTo)),
    fork(takeEveryAction.bind(this, retrieveFollowUpAction.toString(), retrieveFollowUpEmail)),
    fork(takeLatestAction.bind(this, updateFollowUpAction.toString(), updateFollowUpEmail)),
    fork(takeLatestAction.bind(this, retrieveReminderEmailAction.toString(), retrieveReminderEmail)),
    fork(takeLatestAction.bind(this, updateReminderEmailAction.toString(), updateReminderEmail)),
    fork(takeLatestAction.bind(this, retrieveConfirmationEmailAction.toString(), retrieveConfirmationEmail)),
    fork(takeLatestAction.bind(this, updateConfirmationEmailAction.toString(), updateConfirmationEmail)),
    fork(takeLatestAction.bind(this, getCustomEmailDisclaimerAction.toString(), fetchCustomEmailDisclaimer)),
    fork(takeLatestAction.bind(this, getCertificateInfoAction.toString(), fetchCertificateInfo)),
    fork(takeLatestAction.bind(this, updateEmailDisclaimerAction.toString(), updateEmailDisclaimer)),
    fork(takeLatestAction.bind(this, updateCertificateInfoAction.toString(), updateCertificateInfo)),
    fork(takeLatestAction.bind(this, deleteCertificateInfoAction.toString(), deleteCertificateInfo)),
    fork(takeLatestAction.bind(this, getCertificateUrlAction.toString(), getCertificateUrl))
  ]);
}
