import { connect } from 'react-redux';
import { updateRegistrationSettings } from 'Reducks/RegistrationSettings/actions';
import { toggleModal } from 'Reducks/Ui/actions';
import { trackRegistrationUpdated } from 'Mixpanel/Features/Registration/registrationTracker';
import { getDomains, getErrors } from 'Reducks/RegistrationSettings/selectors';
import RestrictDomains from './restrictDomains';

const mapStateToProps = (state, ownProps) => ({
  domainWhitelist: getDomains(state, ownProps),
  domainWhitelistServerErrors: getErrors(state)
});

export const mapDispatchToProps = (dispatch) => ({
  updateRegistrationSettings: (webinarKey, data, type) => dispatch(updateRegistrationSettings(webinarKey, data, type)),
  toggleModal: () => dispatch(toggleModal()),
  trackRegistrationUpdated: (type, action) => trackRegistrationUpdated(type, action)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictDomains);
