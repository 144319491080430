import config from 'appConfig';
import { getUser } from 'Api';
import getHeaders from 'Api/Helpers/getHeaders';
import moment from 'moment';
import { extractNextUpcomingSessionDateTimeForWebinar } from 'Components/Card/Events/webinarDateHelper';
import * as assetsService from 'Api/Assets/assetsService';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { buildQueries, fetchJson, fetchJsonForLiveStream } from '../Helpers/fetch';
import { GET, PUT, DELETE, POST } from '../Helpers/httpMethods';
import { CUSTOM_THEME } from '../../Components/Card/Events/Details/Branding/constants';
import { TOKEN_TYPE } from '../Helpers/getHeaders';

export function getMetadata() {
  const url = `${config.g2w.url}/api/organizers/me/metadata`;
  const options = {
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

export function getSupportedWebinarTypes(accountKey, userKey) {
  const url = `${config.g2w.url}/api/accounts/${accountKey}/users/${userKey}/settings?attributes=isAVBroadcastEnabled,isSimuliveEnabled,isStandardBroadcastProvisioned`;
  const options = {
    headers: getHeaders(true),
    method: GET
  };
  return fetchJson(url, options, 'json');
}

export function getIsVideoPushEnabled(accountKey, userKey) {
  const url = `${config.g2w.url}/api/accounts/${accountKey}/users/${userKey}/settings?attributes=isVideoPushEnabled`;
  const options = {
    headers: getHeaders(true),
    method: 'GET'
  };
  return fetchJson(url, options, 'json');
}

export function getOrganizerWebinarSettings(accountKey, userKey) {
  const url = `${config.g2w.url}/api/accounts/${accountKey}/users/${userKey}/settings?attributes=all&includeEntitlements=true`;
  const options = {
    headers: getHeaders(true),
    method: GET
  };
  return fetchJson(url, options, 'json');
}

export function updateOrganizerWebinarSettings(accountKey, userKey, data) {
  const url = `${config.g2w.url}/api/accounts/${accountKey}/users/${userKey}/settings`;
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(data)
  };
  return fetchJson(url, options, 'json');
}

export function getCalendarDetails(webinarKey, userKey, sourceUrl) {
  return `${config.g2w.url}/api/icsCalendar?webinar=${webinarKey}&user=${userKey}&source=${sourceUrl}`;
}

export function getWebinar(webinarId, includedDetails) {
  const url = includedDetails
    ? `${config.g2w.url}/api/V2/webinars/${webinarId}?includes=${includedDetails}`
    : `${config.g2w.url}/api/webinars/${webinarId}`;
  const options = {
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

export function getWebinars(params) {
  const queries = buildQueries(params);
  const url = `${config.g2w.url}/api/V2/organizers/${getUser().id}/webinars?${queries}`;
  const headers = getHeaders(true);
  headers.Pragma = 'no-cache';

  return fetchJson(url, { headers }, JSON_MIME_TYPE);
}

export function getUpcomingWebinarsForDates(fromDate, toDate) {
  const url = `${config.g2w.url}/api/V2/organizers/${getUser().id}/webinars?fromTime=${fromDate}&toTime=${toDate}&experienceType=ALL`;
  const headers = getHeaders(true, undefined, undefined, true);
  const options = {
    headers
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createBroadcastSession(broadcastConfig) {
  const { organizerKey, productType, productKey, liveStreamingSettingsId } = broadcastConfig;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings/${liveStreamingSettingsId}/start`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'POST',
    body: JSON.stringify({ organizerKey })
  };
  return fetchJsonForLiveStream(url, options, 'json');
}

export function linkedInRelogin(reloginConfig) {
  const { productKey, productType, liveStreamingSettingId, authorizationCode, channelType } = reloginConfig;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings/${liveStreamingSettingId}/regenerateCredentials`;
  const requestBody = {
    channelType,
    customAttributes: {
      authorizationCode
    }
  };
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'PUT',
    body: JSON.stringify(requestBody)
  };
  return fetchJsonForLiveStream(url, options, 'json');
}

export function getActiveTargetsStatus(targetConfig) {
  const { productKey, productType } = targetConfig;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/targets`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'GET'
  };
  return fetchJson(url, options, 'json');
}

export function stopBroadcastSession(deleteConfig) {
  const { productType, productKey, targetId } = deleteConfig;

  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/targets/${targetId}/stop`;

  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'PUT'
  };
  return fetchJson(url, options, 'json');
}

export function getBroadcastStatus(broadcastConfig) {
  const { productType, productKey, targetId } = broadcastConfig;

  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/targets/${targetId}/status`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'GET'
  };
  return fetchJson(url, options, 'json');
}

export function getAllLiveStreamingConfiguration(streamingInfo) {
  const { productType, productKey } = streamingInfo;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'GET'
  };
  return fetchJson(url, options, 'json');
}

export function setLiveStreamingConfig(streamingInfo) {
  const { channelType, productType, streamKey, streamUrl, streamPageUrl, postTitle,
    productKey, customTitle, broadcastAccountType, authorizationCode } = streamingInfo;

  let livestreamingConfigInfo = {
    channelType,
    streamKey,
    streamUrl,
    streamPageUrl,
    customTitle,
    customAttributes: {
      postTitle,
      broadcastAccountType,
      authorizationCode
    }
  };
  let url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings`;
  if (channelType === 'linkedIn') {
    url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/channel/${channelType}/configure`;
    livestreamingConfigInfo = {
      postTitle,
      broadcastAccountType,
      authorizationCode
    };
  }

  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'POST',
    body: JSON.stringify(livestreamingConfigInfo)
  };
  return fetchJsonForLiveStream(url, options, 'json', true);
}

export function updateLiveStreamingChannelConfig(updateInfo) {
  const { productType, productKey, liveStreamingSettingsId, channelType, postTitle,
    streamKey, streamUrl, streamPageUrl, customTitle, accountPreference, version } = updateInfo;
  const updateConfig = {
    channelType,
    streamKey,
    streamUrl,
    streamPageUrl,
    customTitle,
    accountPreference,
    version,
    customAttributes: {
      postTitle
    }
  };
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings/${liveStreamingSettingsId}`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'PUT',
    body: JSON.stringify(updateConfig)
  };
  return fetchJsonForLiveStream(url, options, 'json', false);
}

export function getLiveStreamingConfig(streamingInfo) {
  const { productType, productKey, liveStreamingSettingsId } = streamingInfo;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/livestreamingsettings/${liveStreamingSettingsId}`;

  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'GET'
  };
  return fetchJson(url, options, 'json');
}

export function getLinkedinConfig(linkedinInfo) {
  const { productType, productKey, channelType, authorizationCode } = linkedinInfo;
  const url = `${config.liveStream.url}/goto-livestreaming/product/${productType}/productKey/${productKey}/channel/${channelType}/configure`;

  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: 'POST',
    body: JSON.stringify(authorizationCode)
  };
  return fetchJson(url, options, 'json');
}

export function getPastWebinars(page = 0, pageSize = 200) {
  const fromTime = moment().subtract(10, 'y').utc().format();
  const toTime = moment().utc().format();
  const url = `${config.g2w.url}/api/V2/organizers/${getUser().id}/webinars?fromTime=${fromTime}&toTime=${toTime}&limit=${pageSize}&page=${page}&experienceType=ALL&includes=ACTIVESESSIONSINFO`;
  const options = {
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json').then((res) => {
    const response = { ...res };
    response.webinars = res.data.sort((event1, event2) => {
      const date1 = moment(extractNextUpcomingSessionDateTimeForWebinar(event1).endTime);
      const date2 = moment(extractNextUpcomingSessionDateTimeForWebinar(event2).endTime);
      return date1.isBefore(date2) ? 1 : -1;
    }).filter((webinar) => {
      if (webinar.type === 'sequence') {
        const webinarTimes = extractNextUpcomingSessionDateTimeForWebinar(webinar);
        return moment(webinarTimes.endTime).isBefore(moment());
      }
      return true;
    });
    return response;
  });
}

export function deleteWebinar(webinarId) {
  const url = `${config.g2w.url}/api/organizers/${getUser().id}/webinars/${webinarId}`;
  const options = {
    headers: getHeaders(true),
    method: DELETE
  };
  return fetchJson(url, options, 'json');
}

export function deleteWebinarWithEmail(webinarId, sendEmails = false, body) {
  const url = `${config.g2w.url}/api/V3/webinars/${webinarId}?sendCancellationEmails=${sendEmails}`;
  const options = {
    headers: getHeaders(true),
    method: DELETE,
    body: JSON.stringify(body)
  };

  return fetchJson(url, options, 'json');
}

export function getTimeZones() {
  const url = `${config.g2w.url}/api/timezones?locale=${getUser().locale}`;
  const options = {
    headers: getHeaders(true),
    method: GET
  };

  return fetchJson(url, options, 'json');
}

/* ------------------------------- Registation ------------------------------------------- */

export function getRegistationInfo(recordingKey, registrantKey) {
  const options = {
    headers: getHeaders(true)
  };
  const url = `${config.g2w.url}/api/V2/webinars/${recordingKey}/registrants/${registrantKey}/recordings/${recordingKey}`;
  return fetchJson(url, options, 'json');
}

export function register(recordingKey) {
  const user = getUser();
  const options = {
    headers: getHeaders(false),
    method: 'POST',
    body: JSON.stringify({
      email: user.userName,
      firstName: user.name.givenName,
      lastName: user.name.familyName
    })
  };

  const url = `${config.g2w.url}/api/recordings/${recordingKey}/registrants`;
  return fetchJson(url, options, null, false).then((res) => {
    if (res.status === 200 || res.status === 201 || res.status === 409) {
      return res.json().then((info) => getRegistationInfo(recordingKey, info.registrantKey));
    }
    throw new Error(res.statusText);
  });
}

export function getAllRegistrants(webinarKey, includePaymentInfo = false) {
  const options = {
    headers: getHeaders(true)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrants?includePaymentInfo=${includePaymentInfo}`;
  return fetchJson(url, options, 'json');
}

export function getRegistrantDetails(webinarKey, registrantKey, includePaymentInfo = false) {
  const options = {
    headers: getHeaders(true)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrants/${registrantKey}?includePaymentInfo=${includePaymentInfo}`;
  return fetchJson(url, options, 'json');
}

export function updatePendingRegistrations(webinarKey, body) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/registrants?status=WAITING`;
  return fetchJson(url, options);
}

export function approveDeniedRegistrations(webinarKey, body) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/registrants?status=DENIED`;
  return fetchJson(url, options, 'json');
}

export function cancelRegistrations(webinarKey, body, issueRefund = false) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(body)
  };
  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/registrants?status=APPROVED&issueRefund=${issueRefund}`;
  return fetchJson(url, options, 'json');
}

export function resendConfirmationEmail(webinarKey, body) {
  const options = {
    headers: getHeaders(true),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/sendConfirmation`;
  return fetchJson(url, options, 'json');
}

export function getSourceTrackingInfo(webinarKey) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrationsource`;
  const options = {
    method: GET,
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

export function addSourceTrackingOption(webinarKey, sourceName) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrationsource?name=${sourceName}&status=ENABLED`;
  const options = {
    method: POST,
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

export function archiveSourceTrackingOption(webinarKey, sourceKey) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrationsource?sourceKey=${sourceKey}&status=DISABLED`;
  const options = {
    method: PUT,
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

/* -------------------------------Webinar Details------------------------------------------- */

export function getWebinarDetails(webinarKey, details = []) {
  const headers = getHeaders(true, undefined, undefined, true, undefined, undefined, true);
  const options = {
    headers
  };

  const requests = details.map((detail) => {
    const url = `${config.g2w.url}/api/${detail === 'audio' ? 'V2/' : ''}webinars/${webinarKey}/${detail}`;
    return fetchJson(url, options, 'json');
  });

  return Promise.all(requests);
}
export function getThemes(webinarKey, organizerKeys = []) {
  const headers = getHeaders(true, TOKEN_TYPE.BEARER, undefined, true);
  const options = {
    headers
  };

  const url = `${config.brandingService.url}/api/webinar/themes/list/available${organizerKeys.length ? `?organizerKeys=${organizerKeys.join(',')}` : ''}`;
  return fetchJson(url, options, 'json');
}

export function updateWebinarDetails(webinarKey, details, notifyParticipants = false) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(details)
  };

  const url = `${config.g2w.url}/api/organizers/${getUser().id}/webinars/${webinarKey}?notifyParticipants=${notifyParticipants}`;
  return fetchJson(url, options, 'json');
}

export function updateSettings(webinarKey, content, key) {
  const options = {
    headers: getHeaders(true),
    method: POST,
    body: JSON.stringify(content)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/${key}`;
  return fetchJson(url, options, 'json');
}

export function getWebinarAutoRecorded(organizerKey, webinarKey) {
  const options = {
    headers: getHeaders(true),
    method: GET
  };
  const url = `${config.g2w.url}/api/organizers/${organizerKey}/webinars/${webinarKey}/settings/autoRecorded`;
  return fetchJson(url, options, 'json');
}

export function updateWebinarAutoRecorded(organizerKey, webinarKey, autoRecordEnabled) {
  const options = {
    headers: getHeaders(true),
    method: POST,
    body: JSON.stringify(autoRecordEnabled)
  };
  const url = `${config.g2w.url}/api/organizers/${organizerKey}/webinars/${webinarKey}/settings/autoRecorded`;
  return fetchJson(url, options, 'json').then(() => ({ autoRecordEnabled, webinarKey }));
}

export function updateOrganizers(webinarKey, updatedOrganizers, updatedKeys) {
  const options = {
    headers: getHeaders(true),
    body: JSON.stringify(updatedOrganizers),
    method: PUT
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers`;
  return fetchJson(url, options, 'json').then(() => ({ webinarKey, updatedOrganizers, updatedKeys }));
}

export function updateRegistrationSettings(webinarKey, details) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    // TODO: implement common payload for registration settings
    body: JSON.stringify(details)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/registrationSettings`;
  return fetchJson(url, options, null, false).then((res) => {
    // returns a 201 without a body
    // fetchJson fails when there is no body on calls that needs body.
    if (res.status === 201) return res.status;
    throw Error(res.statusText, res.status);
  });
}

export function getRegistrationSettings(webinarKey) {
  const options = {
    headers: getHeaders(true, undefined, undefined, true),
    method: GET
  };

  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/registrationSettings`;
  return fetchJson(url, options, 'json');
}

function createBrandingFormData(brandingData) {
  const {
    theme, logoImage, themeImage, webinarPresenters, deleteLogo, deleteThemeImage, welcomeAddress, deleteAllPresenters, logoImageUrl, themeImageUrl
  } = brandingData;
  const formData = new FormData();
  if (theme) {
    formData.append('theme.themeType', theme.themeType ? theme.themeType : 'Default');
    if (theme.themeType === CUSTOM_THEME) {
      formData.append('theme.backgroundColor', theme.backgroundColor.replace('#', ''));
      formData.append('theme.borderColor', theme.borderColor.replace('#', ''));
      formData.append('theme.headerColor', theme.headerColor.replace('#', ''));
    }
  }
  if (deleteLogo) {
    formData.append('deleteLogo', true);
  } else if (logoImage) {
    formData.append('logoImage', logoImage, logoImage.name);
  } else if (logoImageUrl) {
    formData.append('logoImageUrl', logoImageUrl);
  }
  if (deleteThemeImage) {
    formData.append('deleteThemeImage', true);
  } else if (themeImage) {
    formData.append('themeImage', themeImage, themeImage.name);
  } else if (themeImageUrl) {
    formData.append('themeImageUrl', themeImageUrl);
  }
  if (deleteAllPresenters) {
    formData.append('deleteAllPresenters', true);
  } else if (webinarPresenters) {
    webinarPresenters.forEach((presenter, index) => {
      if (presenter.key) {
        formData.append(`webinarPresenters[${index}].key`, presenter.key);
      }
      if (presenter.name) {
        formData.append(`webinarPresenters[${index}].name`, presenter.name);
      }
      if (presenter.title) {
        formData.append(`webinarPresenters[${index}].title`, presenter.title);
      }
      if (presenter.organization) {
        formData.append(`webinarPresenters[${index}].organization`, presenter.organization);
      }
      if (presenter.presenterImage) {
        formData.append(`webinarPresenters[${index}].presenterImage`, presenter.presenterImage,
          presenter.presenterImage.name);
      } else if (presenter.imageKey) {
        formData.append(`webinarPresenters[${index}].imageKey`, presenter.imageKey);
      }
    });
  }
  if (welcomeAddress === '') {
    formData.append('deleteWelcomeAddress', true);
  } else if (welcomeAddress) {
    formData.append('welcomeAddress', welcomeAddress);
  }
  return formData;
}

function createBrandingThemeFormData(brandingDataWithTitle) {
  const {
    theme, logoImageUrl, themeImageUrl, title
  } = brandingDataWithTitle;
  const formData = new FormData();
  if (title) {
    formData.append('title', title);
  }
  formData.append('themeType', theme?.themeType ? theme.themeType : 'Default');
  if (theme) {
    if (theme.themeType === CUSTOM_THEME) {
      formData.append('backgroundColor', theme.backgroundColor.replace('#', ''));
      formData.append('borderColor', theme.borderColor.replace('#', ''));
      formData.append('headerColor', theme.headerColor.replace('#', ''));
    }
  }
  if (logoImageUrl) {
    formData.append('logoImageUrl', logoImageUrl);
  } else {
    formData.append('deleteLogoImage', true);
  }
  if (themeImageUrl) {
    formData.append('featureImageUrl', themeImageUrl);
  } else {
    formData.append('deleteFeatureImage', true);
  }
  return formData;
}

export function updateBranding(webinarKey, brandingData) {
  const formData = createBrandingFormData(brandingData);
  const options = {
    headers: getHeaders(true, 'Token', null),
    method: 'POST',
    body: formData
  };
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/branding`;
  return fetchJson(url, options, 'json');
}

export function saveBrandingTheme(brandingDataWithTitle) {
  const formData = createBrandingThemeFormData(brandingDataWithTitle);
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER, null),
    method: 'POST',
    body: formData
  };
  const url = `${config.brandingService.url}/api/webinar/themes`;
  return fetchJson(url, options, 'json');
}

export function updateBrandingTheme(brandingDataWithTitleAndId) {
  const formData = createBrandingThemeFormData(brandingDataWithTitleAndId);
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER, null),
    method: 'PATCH',
    body: formData
  };
  const url = `${config.brandingService.url}/api/webinar/themes/${brandingDataWithTitleAndId.themeId}`;
  return fetchJson(url, options, 'json');
}

export function deleteBrandingTheme(themeId) {
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER, null),
    method: 'DELETE'
  };
  const url = `${config.brandingService.url}/api/webinar/themes/${themeId}`;
  return fetchJson(url, options, 'json');
}

export function fetchSimuliveRecording(webinarKey) {
  return assetsService.getRecordingAssociations('webinar', [webinarKey], [], ['simulive']).then((res) => {
    if (res && res._embedded && res._embedded.associationResources) {
      const assetDetails = res._embedded.associationResources[0];
      const { assetKey } = assetDetails;
      return assetsService.getRecordingByAssetKey(assetKey).then((recording) => Object.assign({ webinarKey }, recording));
    }
    return Promise.reject(new Error('Recording not found'));
  });
}

export function updateRecordingForSimuliveWebinar(webinarKey, assetKey) {
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify({ assetKey })
  };
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/asset`;
  return fetchJson(url, options, 'json');
}

/* --------------------------- Audio Settings -------------------------------------- */
export function getOrganizerAudioSettings() {
  const options = {
    headers: getHeaders(true)
  };

  const url = `${config.g2w.url}/api/V2/organizer/settings/audio`;
  return fetchJson(url, options, 'json');
}

function constructAudioData(audioDetails) {
  const data = {};
  const { audioModes } = audioDetails;

  if (!audioModes) {
    throw Error('Request does not include audio modes. You must send with PRIVATE, or VOIP/TOLL/TOLLFREE');
  }

  data.audioModes = audioModes;
  if (audioModes.includes('PRIVATE')) {
    data.privateAudio = audioDetails.privateAudio;
  } else {
    if (audioModes.includes('TOLL')) {
      data.tollCountries = audioDetails.tollCountries || [];
      if (audioDetails.preferredTollCountry) {
        data.preferredTollCountry = audioDetails.preferredTollCountry;
      }
    }
    if (audioModes.includes('TOLLFREE')) {
      data.tollFreeCountries = audioDetails.tollFreeCountries || [];
      if (audioDetails.preferredTollFreeCountry) {
        data.preferredTollFreeCountry = audioDetails.preferredTollFreeCountry;
      }
    }
  }

  return data;
}

export function updateOrganizerAudioSettings(details) {
  const data = constructAudioData(details);
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(data)
  };

  const url = `${config.g2w.url}/api/V2/organizer/settings/audio`;
  return fetchJson(url, options, 'json');
}

export function updateWebinarAudioSettings(webinarKey, audioDetails, notifyParticipants = false) {
  const data = constructAudioData(audioDetails);
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(data)
  };
  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/audio?notifyParticipants=${notifyParticipants}`;
  return fetchJson(url, options);
}

/* ------------------------------- Staff ------------------------------------------- */

export function getExternalOrganizers() {
  const options = {
    headers: getHeaders(true)
  };

  const url = `${config.g2w.url}/api/organizers/${getUser().id}/externalCoOrganizers`;
  return fetchJson(url, options, 'json');
}

export function getWebinarCoOrganizers(webinarKey) {
  const options = {
    headers: getHeaders(true)
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers`;
  return fetchJson(url, options, 'json');
}

export function resentInvitation(webinarKey, coorganizerKey) {
  const options = {
    headers: getHeaders(true),
    method: POST
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers/${coorganizerKey}/resendInvitation`;
  return fetchJson(url, options, 'json');
}

export function createCoorganizers(webinarKey, newOrganizers) {
  const options = {
    headers: getHeaders(true),
    body: JSON.stringify(newOrganizers),
    method: POST
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers`;
  return fetchJson(url, options, 'json');
}

export function getAccountUsers(organizerKey) {
  const url = `${config.g2w.url}/api/organizers/${organizerKey}/coorganizers`;
  const options = {
    headers: getHeaders(true)
  };

  return fetchJson(url, options, 'json');
}

export function createPanelists(webinarKey, newPanelists) {
  const options = {
    headers: getHeaders(true),
    body: JSON.stringify(newPanelists),
    method: POST
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/panelists`;
  return fetchJson(url, options, 'json');
}

export function deleteCoorganizer(webinarKey, coorganizerKey, external = true) {
  const options = {
    headers: getHeaders(true),
    method: DELETE
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers/${coorganizerKey}?external=${external}`;
  return fetchJson(url, options, 'json');
}

export function deletePanelist(webinarKey, deleteKeys) {
  const options = {
    headers: getHeaders(true),
    method: DELETE
  };
  const requests = deleteKeys.map((panelistKey) => {
    const url = `${config.g2w.url}/api/webinars/${webinarKey}/panelists/${panelistKey}`;
    return fetchJson(url, options, 'json');
  });

  return Promise.all(requests);
}

export function editOrganizer(webinarKey, newOrganizers, deleteKeys) {
  const addRequest = newOrganizers && newOrganizers.length > 0 ? createCoorganizers(webinarKey, newOrganizers) : null;
  const deleteRequest = deleteKeys && deleteKeys.length > 0 ? deleteKeys.map((obj) => deleteCoorganizer(webinarKey, obj.key, obj.external)) : null;

  return Promise.all([addRequest].concat(deleteRequest));
}

export function sendPanelistInviation(webinarKey, panelistKey) {
  const options = {
    headers: getHeaders(true),
    method: POST
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/panelists/${panelistKey}/resendInvitation`;
  return fetchJson(url, options, 'json');
}

export function sendCoOrganizerInviation(webinarKey, organizerKey, external = true) {
  const options = {
    headers: getHeaders(true),
    method: POST
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/coorganizers/${organizerKey}/resendInvitation?external=${external.toString()}`;
  return fetchJson(url, options, 'json');
}

/* ------------------------------- Schedule ------------------------------------------- */
export function scheduleWebinar(organizerKey, webinarData) {
  const options = {
    headers: getHeaders(true),
    body: JSON.stringify(webinarData),
    method: POST
  };

  const url = `${config.g2w.url}/api/organizers/${organizerKey}/webinars`;
  return fetchJson(url, options, 'json');
}

export function copyWebinar(webinarKeyToCopy, webinarData) {
  const options = {
    headers: getHeaders(true),
    body: JSON.stringify(webinarData),
    method: PUT
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKeyToCopy}/copy?includeStaff=true`;
  return fetchJson(url, options, 'json');
}

export function reassignWebinar(webinarKey) {
  const options = {
    headers: getHeaders(true),
    method: GET
  };

  const url = `${config.g2w.url}/api/webinars/${webinarKey}/reassign`;
  return fetchJson(url, options, 'json', false);
}

/* ------------------------------- Launch Webinar ------------------------------------------- */
export function startWebinar(webinarKey, successUrl, mode, authCode, redirectUrl) {
  const options = {
    headers: getHeaders(true)
  };
  const url = `${config.g2w.url}/api/V2/webinars/${webinarKey}/launchWebinar?mode=${mode}&code=${authCode}&redirectUrl=${redirectUrl}&successUrl=${successUrl}`;
  return fetchJson(url, options, 'json');
}

/* ------------------------------- Series Webinar ------------------------------------------- */

export function getSeriesRecurrence(recurrenceKey) {
  const options = {
    headers: getHeaders(false)
  };
  const url = `${config.g2w.url}/api/V2/webinars/${recurrenceKey}`;
  return fetchJson(url, options, 'json');
}

export function getFutureSeriesRecurrence(recurrenceKey) {
  const options = {
    headers: getHeaders(true)
  };
  const url = `${config.g2w.url}/api/V2/recurrence/${recurrenceKey}/webinars?status=future,insession`;
  return fetchJson(url, options, 'json');
}

export function updateSeriesRecurrence(recurrenceKey, times, notifyParticipants = false) {
  const options = {
    headers: getHeaders(true),
    method: POST,
    body: JSON.stringify(times)
  };
  const url = `${config.g2w.url}/api/V2/recurrence/${recurrenceKey}/webinars?notifyParticipants=${notifyParticipants}`;
  return fetchJson(url, options, 'json');
}

export function deleteSingleSeriesWebinar(webinarId, sendEmails = false) {
  const url = `${config.g2w.url}/api/V2/webinars/${webinarId}?sendCancellationEmails=${sendEmails}`;
  const options = {
    headers: getHeaders(true),
    method: DELETE
  };

  return fetchJson(url, options, 'json');
}

export const convertWebinartoWebcast = (webinarId) => {
  const url = `${config.g2w.url}/api/webinars/${webinarId}/webcast`;
  const options = {
    headers: getHeaders(true),
    method: PUT
  };
  return fetchJson(url, options, 'json');
};

export function createUploadPolicy(webinarKey) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/bulkUploadTicket`;
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: POST
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
