import { createSelector } from 'reselect';
import { getNumericUUID } from '../../Utils/randomUuidGenerator';
import { get } from '../../Utils/objectHelpers';

const getSettings = (state) => state.registrationSettings.registration;
const getWebinarKey = (_, props) => props.webinar && props.webinar.webinarKey;

const getDomainsFromSettings = (settings, webinarKey) => {
  if (settings === undefined || webinarKey === undefined) {
    return undefined;
  }
  return settings[webinarKey] && settings[webinarKey].allowedEmailDomains;
};

const domainWhitelistToArray = (domainWhitelist) => {
  if (domainWhitelist === undefined) {
    return [];
  }

  return domainWhitelist
    .split(',')
    .map((i) => i.trim())
    .reduce((agg, curr) => {
      agg.push({
        domainName: curr,
        hasError: false,
        key: getNumericUUID()
      });
      return agg;
    }, []);
};

const getDomainsString = createSelector(
  [getSettings, getWebinarKey],
  getDomainsFromSettings
);

export const getDomains = createSelector(
  getDomainsString,
  domainWhitelistToArray
);

const getServerErrors = (state) => state.registrationSettings.detailsError;

const serverErrorsToArray = (detailsString) => {
  if (!detailsString) {
    return [];
  }
  const searchValue = 'allowedEmailDomains: ';
  const index = detailsString.search(searchValue) + searchValue.length;
  return detailsString
    .slice(index)
    .split(', ')
    .sort();
};

export const getErrors = createSelector(
  getServerErrors,
  serverErrorsToArray
);

export const selectRegistrationSettings = createSelector(
  [
    (state, props) => Object.assign({}, get(state, (s) => s.registrationSettings.registration[props.webinar.webinarKey], {}))
  ],
  (registrationSettings) => registrationSettings
);

export const selectEventRegistrationSettings = createSelector(
  [
    (state, props) => Object.assign({}, get(state, (s) => s.registrationSettings.registration[props.virtualEventDetails.eventKey], {}))
  ],
  (registrationSettings) => registrationSettings
);
