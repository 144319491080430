import WebinarType from '../../../Components/Model/webinarType';
import MixpanelTracker from '../../mixpanelTracker';
import { WEBINAR } from '../../mixpanelConstants';
import Action from '../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function trackWebinarCreated(webinarDetails, occurrenceType, newExperience = false) {
  const properties = {
    Action: Action.CREATE,
    ExperienceType: webinarDetails.experienceType,
    SeriesType: webinarDetails.type,
    RecurrenceType: occurrenceType,
    OnDemand: webinarDetails.experienceType === WebinarType.SIMULIVE,
    NewExperience: newExperience,
    PublishToStage: webinarDetails.publishToStage === true
  };

  if (webinarDetails.recurrenceStart) {
    properties.RecurrenceStartTimeStart = webinarDetails.recurrenceStart.startTime;
    properties.RecurrenceStartTimeEnd = webinarDetails.recurrenceStart.endTime;
  }
  if (webinarDetails.recurrenceEnd) {
    properties.RecurrenceEndDate = webinarDetails.recurrenceEnd;
  }
  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function trackWebinarStarted(webinarDetails, isPractice = false) {
  const properties = {
    Action: isPractice ? Action.PRACTICE : Action.START,
    ExperienceType: webinarDetails.experienceType,
    SeriesType: webinarDetails.type,
    OnDemand: webinarDetails.experienceType === WebinarType.SIMULIVE
  };
  if (webinarDetails.recurrenceStart) {
    properties.RecurrenceStartTimeStart = webinarDetails.recurrenceStart.startTime;
    properties.RecurrenceStartTimeEnd = webinarDetails.recurrenceStart.endTime;
  }
  if (webinarDetails.recurrenceEnd) {
    properties.RecurrenceEndDate = webinarDetails.recurrenceEnd;
  }

  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function trackWebinarJoined(webinarDetails) {
  const properties = {
    Action: Action.JOIN,
    ExperienceType: webinarDetails.experienceType,
    SeriesType: webinarDetails.type
  };
  if (webinarDetails.recurrenceStart) {
    properties.RecurrenceStartTimeStart = webinarDetails.recurrenceStart.startTime;
    properties.RecurrenceStartTimeEnd = webinarDetails.recurrenceStart.endTime;
  }
  if (webinarDetails.recurrenceEnd) {
    properties.RecurrenceEndDate = webinarDetails.recurrenceEnd;
  }
  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackWebinarDeleted(webinarDetails) {
  const properties = {
    Action: Action.REMOVE,
    ExperienceType: webinarDetails.experienceType,
    SeriesType: webinarDetails.type
  };
  if (webinarDetails.recurrenceStart) {
    properties.RecurrenceStartTimeStart = webinarDetails.recurrenceStart.startTime;
    properties.RecurrenceStartTimeEnd = webinarDetails.recurrenceStart.endTime;
  }
  if (webinarDetails.recurrenceEnd) {
    properties.RecurrenceEndDate = webinarDetails.recurrenceEnd;
  }
  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackWebinarShared(webinarDetails, type = '') {
  const properties = {
    Action: Action.SHARE,
    Type: type,
    ExperienceType: webinarDetails.experienceType,
    SeriesType: webinarDetails.type
  };
  if (webinarDetails.recurrenceStart) {
    properties.RecurrenceStartTimeStart = webinarDetails.recurrenceStart.startTime;
    properties.RecurrenceStartTimeEnd = webinarDetails.recurrenceStart.endTime;
  }
  if (webinarDetails.recurrenceEnd) {
    properties.RecurrenceEndDate = webinarDetails.recurrenceEnd;
  }
  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackWebinarShowAll(showAll) {
  const properties = {
    Action: Action.SHOW_ALL,
    'Show All': showAll
  };
  mixpanelTracker.setEventName(WEBINAR);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}
