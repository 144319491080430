import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as RegistrationTypes from 'Components/Card/Events/Details/Registration/registrationTypes';
import * as RegistrationConstants from 'Mixpanel/Features/Registration/registrationConstants';
import clonedeep from 'lodash.clonedeep';
import isequal from 'lodash.isequal';
import Action from 'Mixpanel/Model/action';
import { ResponsiveModal } from '@getgo/chameleon-react';
import AddButton from 'Components/Card/SharedComponents/AddButton';
import classNames from 'classnames';
import { getNumericUUID } from 'Utils/randomUuidGenerator';
import DomainInput from './DomainInput/domainInput';
import styles from './styles.scss';

class RestrictDomainsModal extends Component {
  static propTypes = {
    webinar: PropTypes.object,
    toggleModal: PropTypes.func,
    updateRegistrationSettings: PropTypes.func,
    trackRegistrationUpdated: PropTypes.func,
    domainWhitelist: PropTypes.array,
    domainWhitelistServerErrors: PropTypes.array,
    t: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      allowedDomains: clonedeep(props.domainWhitelist),
      domainCountLimitReached: false
    };
  }

  componentDidUpdate(prevProps) {
    const newDomainWhitelistErrors = this.props.domainWhitelistServerErrors;
    const oldDomainWhitelistErrors = prevProps.domainWhitelistServerErrors;
    if (!isequal(newDomainWhitelistErrors, oldDomainWhitelistErrors)) {
      const allowedDomainsWithServerErrors = this.state.allowedDomains.reduce((agg, curr) => {
        if (newDomainWhitelistErrors.includes(curr.domainName)) {
          agg.push({
            domainName: curr.domainName,
            hasError: true,
            key: curr.key
          });
        } else {
          agg.push(curr);
        }
        return agg;
      }, []);
      this.setState({ allowedDomains: allowedDomainsWithServerErrors });
    }
  }

  canAddDomain = () => {
    const { allowedDomains } = this.state;
    return allowedDomains.length < 50;
  }

  addDomain = () => {
    this.setState({ allowedDomains: [
      ...clonedeep(this.state.allowedDomains),
      {
        domainName: '',
        hasError: false,
        key: getNumericUUID()
      }
    ] });
  };

  onClose = () => {
    this.props.toggleModal();
  }

  onSave = () => {
    const allowedDomainsString = this.state.allowedDomains
      .filter((i) => i.domainName !== '')
      .reduce((agg, curr) => {
        agg.push(curr.domainName.toLowerCase());
        return agg;
      }, [])
      .join(',');

    const webinarKey = this.props.webinar.webinarKey;
    const data = { allowedEmailDomains: allowedDomainsString };
    this.props.updateRegistrationSettings(webinarKey, data, RegistrationTypes.RESTRICT_DOMAINS);
  };

  onDomainUpdated = (domainName, domainIndex, hasError) => {
    const allowedDomainsCopy = clonedeep(this.state.allowedDomains);
    allowedDomainsCopy[domainIndex].domainName = domainName;
    allowedDomainsCopy[domainIndex].hasError = hasError;
    this.setState({ allowedDomains: allowedDomainsCopy });
    this.props.trackRegistrationUpdated(RegistrationConstants.RESTRICT_DOMAINS, Action.EDIT);
  };

  onDomainDeleted = (domainIndex) => () => {
    const allowedDomains = clonedeep(this.state.allowedDomains);
    allowedDomains.splice(domainIndex, 1);
    this.setState({ allowedDomains });
  };

  renderDomains = () => this.state.allowedDomains.map((curr, index) => <DomainInput
    key={curr.key}
    domainIndex={index}
    domainName={curr.domainName}
    onDomainUpdated={this.onDomainUpdated}
    onDomainDeleted={this.onDomainDeleted}
    hasError={curr.hasError}
  />);

  render() {
    const { t } = this.props;
    const hasError = this.state.allowedDomains.some((i) => i.hasError === true);
    const canAddDomain = this.canAddDomain();
    const addDomainInfoStyles = classNames(styles.addDomainInfo, canAddDomain ? null : styles.warning);
    const addDomainInfoText = canAddDomain
      ? t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfo')
      : t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfoLimitReached');
    return (
      <ResponsiveModal
        onClose={this.onClose}
        isOpen={true}
        title={t('cards.webinar.details.registration.restrictDomainsModal.title')}
        titleId='restrict_domains_modal_title'
        customContainerClass={styles.modal}
        primaryActionButton={{
          text: t('button.save'),
          onClick: this.onSave,
          id: 'restrict_domains_modal_save_details',
          disabled: hasError
        }}
        secondaryActionButton={{
          text: t('button.cancel'),
          onClick: this.onClose,
          id: 'restrict_domains_modal_cancel'
        }}
      >
        <div className={styles.container} id='restrict_domains_modal_content'>
          <div className={styles.domainsInfo} id='restrict_domains_modal_info'>{t('cards.webinar.details.registration.restrictDomainsModal.info')}</div>
          {this.renderDomains()}
          <div className={styles.addButtonContainer}>
            <AddButton className={styles.addButton} id='add_domain_button' title={'Domain'} onClick={this.addDomain} isDisabled={!canAddDomain} />
            <div className={addDomainInfoStyles}>{addDomainInfoText}</div>
          </div>
        </div>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(RestrictDomainsModal);
